<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <el-card class="box-card order-detail">
            <div slot="header" class="clearfix">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/member/book_list' }">预订单列表</el-breadcrumb-item>
                    <el-breadcrumb-item>订单详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div v-loading="loading">
                <template v-if="orderDetail">
                    <div class="order-status" style="position: relative;">
                        <h4>
                            订单状态：
                            <span class="ns-text-color">{{ orderDetail.order_status_name }}</span>
                            <el-button class="order export_detail" @click="exportTable"><img src="../../assets/images/alibaba/excel.png" />导出Excel</el-button>
                        </h4>
                        <div v-if="orderDetail.order_status == 0" class="go-pay">
                            <p>
                                需付款：
                                <span>￥{{ orderDetail.pay_money }}</span>
                            </p>
                        </div>

                        <div class="operation" v-if="orderDetail.action.length > 0">
                            <el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1" @click="operation('memberOrderEvaluation')">
                                <template v-if="orderDetail.evaluate_status == 0"> 评价 </template>
                                <template v-else-if="orderDetail.evaluate_status == 1"> 追评 </template>
                            </el-button>

                            <el-button type="primary" size="mini" :plain="operationItem.action == 'orderPay' ? false : true" v-for="(operationItem, operationIndex) in orderDetail.action" :key="operationIndex" @click="operation(operationItem.action)">
                                {{ operationItem.title }}
                            </el-button>
                        </div>
                        <div class="operation" v-else-if="orderDetail.action.length == 0 && orderDetail.is_evaluate == 1">
                            <el-button type="primary" size="mini" plain v-if="orderDetail.is_evaluate == 1" @click="operation('memberOrderEvaluation')">
                                <template v-if="orderDetail.evaluate_status == 0"> 评价 </template>
                                <template v-else-if="orderDetail.evaluate_status == 1"> 追评 </template>
                            </el-button>
                        </div>
                    </div>

                    <div class="order-info">
                        <h4>订单信息</h4>
                        <ul>
                            <!-- <li>
								<i class="iconfont iconmendian"></i>
								店铺：
								<router-link :to="'/shop-' + orderDetail.site_id" target="_blank">{{ orderDetail.site_name }}</router-link>
							</li> -->
                            <li>订单类型：{{ orderDetail.order_type_name }}</li>
                            <li>订单编号：{{ orderDetail.order_no }}</li>
                            <li>订单交易号：{{ orderDetail.out_trade_no }}</li>
                            <li>配送方式：{{ orderDetail.delivery_type_name }}</li>
                            <li>创建时间：{{ $util.timeStampTurnTime(orderDetail.create_time) }}</li>
                            <li v-if="orderDetail.close_time > 0">关闭时间：{{ $util.timeStampTurnTime(orderDetail.close_time) }}</li>
                            <template v-if="orderDetail.pay_status > 0">
                                <li>支付方式：{{ orderDetail.pay_type_name }}</li>
                                <li>支付时间：{{ $util.timeStampTurnTime(orderDetail.pay_time) }}</li>
                            </template>
                            <li v-if="orderDetail.promotion_type_name != ''">店铺活动：{{ orderDetail.promotion_type_name }}</li>
                            <li v-if="orderDetail.buyer_message != ''">买家留言：{{ orderDetail.buyer_message }}</li>
                        </ul>
                    </div>

                    <div class="take-delivery-info">
                        <h4>收货信息</h4>
                        <ul>
                            <li>用户名：{{ memberDetail.nickname }}</li>
                            <li>客户经理：{{ managerName }}</li>
                            <li>会员等级：{{ memberDetail.member_level_name }}</li>
                            <li>收货地址：{{ orderDetail.full_address }} {{ orderDetail.address }}</li>
                            <li>收货人姓名：{{ orderDetail.name }}</li>
                            <li>联系电话：{{ orderDetail.mobile }}</li>
                            <li>其他备注：</li>
                        </ul>
                    </div>

                    <!-- <nav>
                        <li :class="{ 'no-operation': !orderDetail.is_enable_refund }">商品信息</li>
                        <li>单价</li>
                        <li>数量</li>
                        <li>小计</li>
                        <li v-if="orderDetail.is_enable_refund">操作</li>
                    </nav> -->

                    <!-- 订单项·商品 -->
                    <!-- <div class="list">
                        <ul class="item" v-for="(goodsItem, goodsIndex) in orderDetail.order_goods" :key="goodsIndex">
                            <li :class="{ 'no-operation': !orderDetail.is_enable_refund }">
                                <div class="img-wrap" @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">
                                    <img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
                                </div>
                                <div class="info-wrap">
                                    <h5 @click="$router.pushToTab('/sku-' + goodsItem.sku_id)">{{ goodsItem.sku_name }}</h5>
                                </div>
                            </li>
                            <li>
                                <span>￥{{ goodsItem.price }}</span>
                            </li>
                            <li>
                                <span>{{ goodsItem.num }}</span>
                            </li>
                            <li>
                                <span>￥{{ (goodsItem.price * goodsItem.num).toFixed(2) }}</span>
                            </li>
                            <li v-if="orderDetail.is_enable_refund">
                                <el-button type="primary" plain size="mini" v-if="goodsItem.refund_status == 0" @click="$router.push({ path: '/member/refund', query: { order_goods_id: goodsItem.order_goods_id, order_id: orderId } })"> 退款 </el-button>
                                <el-button type="primary" plain size="mini" v-else @click="$router.push({ path: '/member/refund_detail', query: { order_goods_id: goodsItem.order_goods_id } })"> 查看退款 </el-button>
                            </li>
                        </ul>
                    </div> -->
                    <div class="table">
                        <el-table ref="multipleTable" class="item" :data="orderdetails" @selection-change="setCheck" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }">
                            <el-table-column type="selection" width="50" align="center"> </el-table-column>
                            <el-table-column prop="order_no" label="商品信息" align="center" width="150px">
                                <template slot-scope="scope">
                                    <a :href="'/sku-'+scope.row.order_goods_id">
                                    <img :src="$img(scope.row.sku_image)" min-width="70" height="70" />
                                    </a>
                                </template>
                            </el-table-column>
                            <el-table-column prop="goods_name" label="商品名称" align="center">
                            <template slot-scope="scope">
                              <a :href="'/sku-'+scope.row.goods_id">
                                {{ scope.row.goods_name }}
                              </a>
                            </template>
                            </el-table-column>
                            <el-table-column prop="goods_no" label="商品货号" align="center" width="80"> </el-table-column>
                            <el-table-column prop="sku_no" label="国际条码" align="center" width="80"> </el-table-column>
                            <el-table-column prop="color_name" label="商品属性" align="center" width="80"> </el-table-column>
                            <el-table-column prop="price" label="您的价格" align="center"> </el-table-column>
                            <el-table-column prop="num" label="订购数量" align="center"> </el-table-column>
                            <el-table-column prop="goods_stock" label="库存数量" align="center"> </el-table-column>
                            <el-table-column label="小计" align="center" width="100px">
                                  <template slot-scope="scope">
                                      {{ (scope.row.price * scope.row.num).toFixed(2) }}
                                  </template>
                              </el-table-column>
                            <el-table-column prop="remarks" label="备注" align="center"> </el-table-column>
                        </el-table>
                    </div>
                    <!-- 拆分订单 -->
                    <el-button class="order" @click="splitOrder()" style="margin-bottom: 20px;"><img src="~img/alibaba/excel.png" />拆分订单</el-button>

                    <!-- 订单总计 -->
                    <ul class="total">
                          <li>
                              <label>订购金额：</label>
                              <span>￥{{ orderDetail.goods_money }}</span>
                          </li>
                          <li>
                              <label>实配金额：</label>
                              <span>￥{{ orderDetail.yijiaohuo_price.toFixed(2) }}</span>
                          </li>
<!--                          <li>-->
<!--                              <label>未交货商品金额：</label>-->
<!--                              <span>￥{{ orderDetail.weijiaohuo_price.toFixed(2) }}</span>-->
<!--                          </li>-->
                          <li>
                            <label>运费：</label>
                            <span>￥{{ parseFloat(orderDetail.delivery_money).toFixed(2) }}</span>
                          </li>
                          <li>
                            <label>包装标签费用：</label>
                            <span>￥{{ parseFloat(orderDetail.pack_money).toFixed(2) }}</span>
                          </li>
                          <li>
                            <label>其他费用：</label>
                            <span>￥{{ parseFloat(orderDetail.other_money).toFixed(2) }}</span>
                          </li>
                          <li>
                            <label>订单应付金额：</label>
                            <span>￥{{ (parseFloat(orderDetail.yijiaohuo_price)+parseFloat(orderDetail.delivery_money)+parseFloat(orderDetail.pack_money)+parseFloat(orderDetail.other_money)).toFixed(2) }}</span>
                          </li>
                        <!-- <li>
                            <label>运费：</label>
                            <span>￥{{ orderDetail.delivery_money }}</span>
                        </li>
                        <li v-if="orderDetail.invoice_money > 0">
                            <label>税费：</label>
                            <span>￥{{ orderDetail.invoice_money }}</span>
                        </li>
                        <li v-if="orderDetail.invoice_delivery_money > 0">
                            <label>发票邮寄费：</label>
                            <span>￥{{ orderDetail.invoice_delivery_money }}</span>
                        </li>
                        <li v-if="orderDetail.adjust_money != 0">
                            <label>订单调整：</label>
                            <span>
                                <template v-if="orderDetail.adjust_money < 0"> - </template>
                                <template v-else> + </template>
                                ￥{{ orderDetail.adjust_money | abs }}
                            </span>
                        </li>
                        <li v-if="orderDetail.promotion_money > 0">
                            <label>优惠：</label>
                            <span>￥{{ orderDetail.promotion_money }}</span>
                        </li>
                        <li v-if="orderDetail.coupon_money > 0">
                            <label>优惠券：</label>
                            <span>￥{{ orderDetail.coupon_money }}</span>
                        </li>
                        <li v-if="orderDetail.point_money > 0">
                            <label>积分抵扣：</label>
                            <span>￥{{ orderDetail.point_money }}</span>
                        </li>
                        <li v-if="orderDetail.balance_money > 0">
                            <label>使用余额：</label>
                            <span>￥{{ orderDetail.balance_money }}</span>
                        </li>
                        <li class="pay-money">
                            <label>实付款：</label>
                            <span>￥{{ orderDetail.pay_money }}</span>
                        </li> -->
                    </ul>
                </template>
            </div>
        </el-card>
    </div>
</template>

<script>
import {apiBookOrderExport, apiOrderDetail, splitOrder} from "@/api/order/order"
import orderMethod from "@/utils/orderMethod"
import { mapGetters } from "vuex"
import Config from "@/utils/config";
export default {
    name: "order_detail",
    components: {},
    mixins: [orderMethod],
    data: () => {
        return {
            orderId: 0,
            orderDetail: null,
            memberDetail: null,
            orderdetails: [],
            orderLogList:[],
            managerName: "",
            loading: true,
            yes: true
        }
    },
    created() {
        this.orderId = this.$route.query.order_id
        this.getOrderDetail()
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"])
    },
    methods: {
        getOrderDetail() {
            apiOrderDetail({
                order_id: this.orderId
            })
                .then((res) => {
                    if (res.code >= 0) {
                        this.orderDetail = res.data
                        console.log(res.data.order_goods.length)
                        this.memberDetail = res.member_detail
                        this.managerName = res.manager_name
                        this.loading = false
                        // console.log(res.data.order_goods)
                        this.orderdetails = res.data.order_goods
                        this.orderLogList = res.loglist;
                    } else {
                        this.$message({
                            message: "未获取到订单信息",
                            type: "warning",
                            duration: 2000,
                            onClose: () => {
                                this.$router.push({
                                    path: "/member/order_list"
                                })
                            }
                        })
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error({
                        message: err.message,
                        duration: 2000,
                        onClose: () => {
                            this.$router.push({
                                path: "/member/order_list"
                            })
                        }
                    })
                })
        },
        // 选中订单
        setCheck(e) {
            this.checkOrders = e
        },
        splitOrder() {
            let order_goods_id = []
            for (let i in this.checkOrders) {
                order_goods_id.push(this.checkOrders[i].order_goods_id)
            }
            let brr = []
            brr.order_goods_id = order_goods_id
            let order_merge = []
            // 判断订单状态
            console.log("订单状态", this.orderDetail.order_status);
            //if (this.orderDetail.order_status_name != "待审核") {
            if (this.orderDetail.order_status != 5) {
                this.open()
                // console.log(123456789)
                // console.log(this.orderDetail.order_status_name)
                return
            }
            // 只有一种商品时不可拆分
            if (this.orderDetail.order_goods.length == 1) {
                this.open1()
                // console.log(44566)
                return
            }
            // 拆分订单全选的情况
            // if ((this.orderDetail.order_goods.length = order_goods_id.length)) {
            //     this.open3()
            //     return
            // }
            console.log("这是："+this.orderDetail.order_goods.length)
            console.log("这是是："+order_goods_id.length)
            splitOrder({
                order_goods_id,
                order_id: this.checkOrders[0].order_id
            })
                .then((res) => {
                    if (res.code == 0) {
                        // this.getOrderList()
                        console.log(123)
                        this.open2()
                        this.getOrderDetail()
                    }
                    // this.loading = false
                    // this.getOrderDetail()
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        open() {
            this.$alert("仅支持订单状态为待审核的订单", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //     type: "info",
                    //     message: `action: ${action}`
                    // })
                }
            })
        },
        open1() {
            this.$alert("单个商品不可拆分", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //     type: "info",
                    //     message: `action: ${action}`
                    // })
                }
            })
        },
        open2() {
            this.$alert("拆分订单成功", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //     type: "info",
                    //     message: `action: ${action}`
                    // })
                }
            })
        },
        open3() {
            this.$alert("商品不可全部拆分", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //     type: "info",
                    //     message: `action: ${action}`
                    // })
                }
            })
        },
        exportTable() {
          this.loading = true
          //调整为调用接口导出
          apiBookOrderExport({
            order_id: this.orderId,
          })
              .then((res) => {
                console.log("订单导出", res);
                if (res.code >= 0) {
                  this.loading = false
                  window.open(Config.imgDomain+res.data.path, '_blank');
                } else {
                  this.$message({
                    message: "未获取到订单信息",
                    type: "warning",
                    duration: 2000
                  })
                }
              })
              .catch((err) => {
                this.loading = false
                this.$message.error({
                  message: err.message,
                  duration: 2000
                })
              })
          return;

          const _this = this
          let xlsx = require("xlsx")
          let tableData = [];
          tableData.push(["订单号：", _this.orderDetail.out_trade_no]);
          /*
          //订单信息
          tableData.push(["订单状态：", _this.orderDetail.order_status_name]);
          //订单信息
          tableData.push(["订单信息"]);
          tableData.push([
            "订单类型：",_this.orderDetail.order_type_name,
            "订单编号:", _this.orderDetail.out_trade_no,
            "订单交易号:", _this.orderDetail.out_trade_no,
          ]);
          tableData.push([
            "配送方式：",_this.orderDetail.delivery_type_name,
            "创建时间:", _this.$util.timeStampTurnTime(_this.orderDetail.create_time),
            _this.orderDetail.close_time > 0 ? '关闭时间:' : '',
            _this.orderDetail.close_time > 0 ? _this.$util.timeStampTurnTime(_this.orderDetail.close_time) : ''
          ]);
          if(_this.orderDetail.pay_status>0) {
            tableData.push([
              "支付方式：",_this.orderDetail.pay_type_name,
              "支付时间:", _this.$util.timeStampTurnTime(_this.orderDetail.pay_time)
            ]);
          }
          tableData.push([
            _this.orderDetail.promotion_type_name != '' ? '店铺活动:' : '',
            _this.orderDetail.promotion_type_name != '' ? _this.orderDetail.promotion_type_name : '',
            _this.orderDetail.buyer_message != '' ? '买家留言:' : '',
            _this.orderDetail.buyer_message != '' ? _this.orderDetail.buyer_message : ''
          ]);
          //收货信息
          tableData.push(["收货信息"]);
           */
          tableData.push([
            "用户名：", _this.memberDetail.nickname,
            "客户经理:", _this.managerName,
            "会员等级:", _this.memberDetail.member_level_name,
          ]);
          tableData.push([
            "收货地址：", _this.orderDetail.full_address + _this.orderDetail.address,
            "收货人姓名:", _this.orderDetail.name,
            "联系电话:", _this.orderDetail.mobile,
          ]);
          tableData.push(["商品信息"]);
          tableData.push(["商品名称", "商品货号", "国际条码", "商品属性", "您的价格", "库存数量","预定数量", "已交货数量", "未交货数量", "备注"]);
          this.orderdetails.forEach((item, index) => {
            let rowData = [];
            //导出内容的字段
            rowData = [
              item.goods_name,
              item.goods_no,
              item.sku_no,
              item.color_name,
              parseFloat(item.price),
              parseInt(item.goods_stock),
              parseInt(item.num),
              parseInt(item.delivery_num),
              parseInt(item.num)-parseInt(item.delivery_num),
              item.delivery_status_name+(item.remarks?"\n"+item.remarks:''),
              //parseFloat((item.price * item.num).toFixed(2))
            ];
            tableData.push(rowData)
          })
          this.orderLogList.forEach((item, index) => {
            tableData.push(["客服修改金额说明:"+item.content]);
          });
          tableData.push(["下单时间:"+_this.$util.timeStampTurnTime(_this.orderDetail.create_time)]);//ok
          tableData.push(["订单状态:"+_this.orderDetail.order_status_name]);//ok
          let ws = xlsx.utils.aoa_to_sheet(tableData)
          let wb = xlsx.utils.book_new()
          xlsx.utils.book_append_sheet(wb, ws, "订单详细") // 工作簿名称
          xlsx.writeFile(wb, "订单详细.xlsx") // 保存的文件名
        },
        operation(action) {
            switch (action) {
                case "orderPay": // 支付
                    this.orderPay(this.orderDetail)
                    break
                case "orderClose": //关闭
                    this.orderClose(this.orderDetail.order_id, () => {
                        this.getOrderDetail()
                    })
                    break
                case "memberTakeDelivery": //收货
                    this.orderDelivery(this.orderDetail.order_id, () => {
                        this.getOrderDetail()
                    })
                    break
                case "trace": //查看物流
                    this.$router.push({
                        path: "/member/logistics",
                        query: {
                            order_id: this.orderDetail.order_id
                        }
                    })
                    break
                case "memberOrderEvaluation": //评价
                    this.$router.pushToTab({
                        path: "/evaluate",
                        query: {
                            order_id: this.orderDetail.order_id
                        }
                    })
                    break
            }
        },
        imageError(index) {
            this.orderDetail.order_goods[index].sku_image = this.defaultGoodsImage
        }
    },
    filters: {
        abs(value) {
            return Math.abs(parseFloat(value)).toFixed(2)
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.order-detail {
    .order-status {
        background-color: #fff;
        margin-bottom: 20px;

        h4 {
            margin: 10px 0 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
        }

        .go-pay {
            p {
                display: inline-block;
                vertical-align: middle;

                span {
                    font-weight: bold;
                    color: $base-color;
                    font-size: 18px;
                }
            }
        }

        .operation {
            margin-top: 10px;
        }
    }

    .order-info {
        background-color: #fff;
        margin-bottom: 10px;

        h4 {
            margin: 10px 0 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                flex: 0 0 33.3333%;
                margin-bottom: 10px;

                &:last-child {
                    flex: initial;
                }
            }
        }
    }

    .take-delivery-info {
        background-color: #fff;
        margin-bottom: 10px;

        h4 {
            margin: 10px 0 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                flex: 0 0 33.3333%;
                margin-bottom: 10px;

                &:last-child {
                    flex: initial;
                }
            }
        }
    }

    nav {
        overflow: hidden;
        padding: 10px 0;
        background: #fff;
        border-bottom: 1px solid #eeeeee;

        li {
            float: left;

            &:nth-child(1) {
                width: 50%;

                &.no-operation {
                    width: 60%;
                }
            }

            &:nth-child(2) {
                width: 15%;
            }

            &:nth-child(3) {
                width: 10%;
            }

            &:nth-child(4) {
                width: 15%;
            }

            &:nth-child(5) {
                width: 10%;
            }
        }
    }

    .list {
        border-bottom: 1px solid #eeeeee;

        .item {
            background-color: #fff;
            padding: 10px 0;
            overflow: hidden;

            li {
                float: left;
                line-height: 60px;

                &:nth-child(1) {
                    width: 50%;
                    line-height: inherit;

                    &.no-operation {
                        width: 60%;
                    }

                    .img-wrap {
                        width: 60px;
                        height: 60px;
                        float: left;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    .info-wrap {
                        margin-left: 70px;

                        h5 {
                            font-weight: normal;
                            font-size: $ns-font-size-base;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-right: 10px;
                            display: inline-block;
                            cursor: pointer;

                            &:hover {
                                color: $base-color;
                            }
                        }

                        span {
                            font-size: $ns-font-size-sm;
                            color: #9a9a9a;
                        }
                    }
                }

                &:nth-child(2) {
                    width: 15%;
                }

                &:nth-child(3) {
                    width: 10%;
                }

                &:nth-child(4) {
                    width: 15%;
                }

                &:nth-child(5) {
                    width: 10%;
                }
            }
        }
    }

    // 总计
    .total {
        padding: 20px;
        background-color: #fff;
        text-align: right;

        li {
            span {
                width: 150px;
                display: inline-block;
            }

            &.pay-money {
                font-weight: bold;

                span {
                    color: $base-color;
                    font-size: 15px;
                    vertical-align: middle;
                }
            }
        }
    }
}

.order {
    margin-top: 20px;
    float: left;
    height: 40px;
    background-color: #ed6030;
    color: #ffffff;
    font-size: $ns-font-size-lg;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    img {
        width: 22px;
        height: 22px;
        margin-right: 5px;
    }
}
.export_detail{
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  height: 30px;
  line-height: 30px;
  background-color: #2d9842;
}
</style>
